<template>
    <crud-index-component v-bind="$data">
        <template slot="header">{{ $t('crud.products._plural') }}</template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        name: 'products-index-component',
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('products'),
                fields: [{
                        title: this.$t('crud.id'),
                        name: 'id',
                        sortField: 'id'
                    },
                    {
                        title: this.$t('crud.name'),
                        name: 'name',
                        sortField: 'name'
                    },
                    {
                        title: this.$t('crud.weight'),
                        name: 'weight',
                        sortField: 'weight'
                    },
                    {
                        title: this.$t('crud.updated_at'),
                        name: 'updated_at',
                        sortField: 'updated_at'
                    },
                    {
                        title: this.$t('crud.actions'),
                        name: 'slot-actions'
                    }
                ],
            };
        }
    }
</script>
